const { get } = require('lodash');
const {
    CONFIGURABLE_PAYMENT_METHODS,
    paymentMethodConfigProp,
} = require('./paymentMethods');

function getDefaultPaymentMethod(pagePayments, currency, paymentModeProp) {
    const gatewayConfigList = get(pagePayments, currency, []);
    for (const config of gatewayConfigList) {
        for (const method of CONFIGURABLE_PAYMENT_METHODS) {
            if (
                get(config, paymentMethodConfigProp(method), false) &&
                (!paymentModeProp || get(config, paymentModeProp))
            ) {
                return method;
            }
        }
    }

    return null;
}

function getPaymentConfig(currencyPayments, paymentMethod) {
    if (!currencyPayments?.length) {
        return null;
    }

    const defaultConfig = currencyPayments[0];

    const methodConfigProp = paymentMethodConfigProp(paymentMethod);
    if (!methodConfigProp) {
        return defaultConfig;
    }

    const paymentConfig = currencyPayments.find(config =>
        get(config, methodConfigProp, false),
    );
    if (!paymentConfig) {
        return defaultConfig;
    }

    return paymentConfig;
}

function isPaymentMethodEnabled(currencyPayments, paymentMethod) {
    if (!currencyPayments?.length) {
        return false;
    }

    const methodConfigProp = paymentMethodConfigProp(paymentMethod);
    return currencyPayments.some(config =>
        get(config, methodConfigProp, false),
    );
}

module.exports = {
    getDefaultPaymentMethod,
    getPaymentConfig,
    isPaymentMethodEnabled,
};
