import axios from 'axios';
import querystring from 'query-string';
import api from '../api';
import Repository from './Repository';
import { CardNumberElement, IdealBankElement } from '@stripe/react-stripe-js';
import { getVoucherForDonation } from 'common/helpers/vouchers';
import { donationFlow } from 'common/helpers/donation';
import { getTrackId } from 'common/helpers/trackId';
import { urlencode } from 'common/helpers/urlencode';
import { PAYMENT_METHODS } from 'common/constants';
import config from 'client/config';

class GatewayError extends Error {
    constructor(message) {
        super(message);
        this.message = message || 'Gateway Error';
        this.name = 'GatewayError';
    }
}

class DonationRepository extends Repository {
    async import({ file, campaignId }) {
        let data = new FormData();
        data.append('file', file[0]);
        data.append('campaignId', campaignId);

        return this.axios.post(`/import`, data);
    }

    authenticationFlow = {
        ideal: async (serverData, props) => {
            if (serverData.isPaymentIntentCompleted) {
                return;
            }
            const currentUrl = new URL(window.location.href);
            const currentUrlWithoutQuery =
                currentUrl.origin + currentUrl.pathname;
            const { paymentIntent, error } =
                await props.stripe.confirmIdealPayment(
                    serverData.clientSecret,
                    {
                        payment_method: props.paymentMethod,
                        return_url: `${currentUrlWithoutQuery}${serverData.redirectQuery}`,
                    },
                    { handleActions: false },
                );
            if (error) {
                throw new Error(error.message);
            }
            if (paymentIntent.status === 'requires_action') {
                const nextAction = paymentIntent.next_action.redirect_to_url;
                return {
                    redirect: true,
                    redirectUrl: nextAction.url,
                };
            }
        },
    };

    postDonationFlow = {
        stripe: async (serverData, props) => {
            if (serverData.isPaymentIntentCompleted) {
                return;
            }
            const { paymentIntent, error } =
                await props.stripe.confirmCardPayment(serverData.clientSecret, {
                    payment_method: props.paymentMethod,
                });

            if (error) {
                throw new Error(error.message);
            } else if (paymentIntent.status !== 'succeeded') {
                throw new Error(
                    `Remote gateway error, status: ${paymentIntent.status}`,
                );
            }
        },
        nedarim: async (serverData, props) => {
            const result = await props.getMessageResponse({
                Name: 'FinishTransaction',
                Value: serverData.id,
            });
            const responseData = result?.Value;
            if (responseData?.Status !== 'OK') {
                if (responseData?.BackMessage == 'NEED CAPTCHA') {
                    throw new Error(
                        props.formatMessage({
                            id: 'Checkout.nedarim.recaptchaError',
                            defaultMessage: `Please update re-captcha underneath the Credit Card details`,
                        }),
                    );
                } else {
                    throw new Error(responseData?.Message);
                }
            }
        },
    };

    async donateAway({
        campaignId,
        data,
        donorPflSourceId,
        flow,
        gateway,
        matchedDonationId,
        voucher,
    }) {
        let awayUrl = '';

        if (Array.isArray(data)) {
            let paramString = urlencode(JSON.stringify(data));
            awayUrl = `${
                config.publicHost
            }/api/donation/${campaignId}/donate/away?params=${paramString}&voucher=${voucher}&trackingId=${getTrackId()}`;
        } else {
            if (data.layerItem) {
                data.layerItemId = data.layerItem.id;
            }
            awayUrl = `${
                config.publicHost
            }/api/donation/${campaignId}/donate/away?${querystring.stringify({
                ...data,
                pre: true,
                trackingId: getTrackId(),
            })}`;
        }

        if (matchedDonationId) {
            awayUrl = `${awayUrl}&matchedDonationId=${matchedDonationId}`;
        }

        if (donorPflSourceId) {
            awayUrl = `${awayUrl}&donorPflSourceId=${donorPflSourceId}`;
        }

        return {
            redirect: true,
            redirectUrl: awayUrl,
            data,
            gateway,
            flow,
        };
    }

    async donateIntent({
        campaignId,
        data,
        gateway,
        props,
        gatewayToken,
        recaptchaToken,
        matchedDonationId,
        donorPflSourceId,
    }) {
        const isCashApp = data[0].paymentMethod === PAYMENT_METHODS.CASHAPP;
        const isIdeal = data[0].paymentMethod === PAYMENT_METHODS.IDEAL;

        const donData = {
            donations: data,
        };

        if (!gatewayToken) {
            const billingDetails = {
                name: [data[0].firstName, data[0].lastName]
                    .filter(Boolean)
                    .join(' '),
                email: data[0].email,
                phone: data[0].phone,
                //TODO: Add address to stripe
            };
            if (billingDetails.phone === '') {
                delete billingDetails.phone;
            }

            const paymentMethodOptions = {
                billing_details: billingDetails,
            };

            if (isCashApp) {
                paymentMethodOptions.type = 'cashapp';
            } else if (isIdeal) {
                paymentMethodOptions.type = 'ideal';
                paymentMethodOptions.ideal =
                    props.elements.getElement(IdealBankElement);
            } else {
                paymentMethodOptions.type = 'card';
                paymentMethodOptions.card =
                    props.elements.getElement(CardNumberElement);
            }

            const { paymentMethod, error } =
                await props.stripe.createPaymentMethod(paymentMethodOptions);

            if (error) {
                throw new Error(error.message);
            }

            donData.gatewayToken = paymentMethod.id;
            props.paymentMethod = paymentMethod.id;
        } else {
            props.paymentMethod = gatewayToken.id;
            donData.gatewayToken = gatewayToken.id;
        }

        const response = await this.axios.post(`/${campaignId}/donate`, {
            ...donData,
            recaptchaToken,
            matchedDonationId,
            donorPflSourceId,
            intent: true,
        });

        if (isCashApp) {
            return response;
        }

        if (isIdeal) {
            return await this.authenticationFlow.ideal(response.data, props);
        }

        if (!response.data.isComplete) {
            await this.postDonationFlow[gateway](response.data, props);
        }

        return response;
    }

    async donateCharge({
        campaignId,
        data,
        voucher,
        gateway,
        props,
        gatewayToken,
        recaptchaToken,
        matchedDonationId,
        donorPflSourceId,
    }) {
        const response = await this.axios.post(`/${campaignId}/donate`, {
            donations: data,
            voucher,
            gatewayToken,
            recaptchaToken,
            matchedDonationId,
            donorPflSourceId,
        });

        if (gateway !== 'paypal' && !response.data.isComplete) {
            //to be refactored
            await this.postDonationFlow[gateway](response.data, props);
        }

        return response;
    }

    async donateOffline({ campaignId, data }) {
        const response = await this.axios.post(`/${campaignId}/offline`, {
            donations: data,
        });

        return response;
    }

    async donate(donationsData) {
        switch (donationsData.flow) {
            case 'awayPre':
                return this.donateAway(donationsData);
            case 'intent':
                return this.donateIntent(donationsData);
            case 'offline':
                return this.donateOffline(donationsData);
            case 'charge':
                return this.donateCharge(donationsData);
            default:
                return this.axios.post(`/${donationsData.campaignId}/donate`, {
                    ...donationsData.data,
                    matchedDonationId: donationsData.matchedDonationId,
                    donorPflSourceId: donationsData.donorPflSourceId,
                });
        }
    }

    /**
     * Make few differents steps for donation creation, depends from payment type and method
     *
     * @param {{}} donationParameters Donation parameters (type, gateways, donation data etc.)
     * @param {campaignId} donationParameters.campaignId Campaign unique identifier
     * @param {{}} donationParameters.donation Donation object with amount, months, currency etc. information.
     * @param {string} donationParameters.paymentGateway Donation payment gateway key
     * @param {{}} [donationParameters.props.stripe = null] Stripe gateway response object
     * @param {{}} [donationParameters.props.elements = null] Stripe form elements refs
     * @param {{}} [donationParameters.props.getMessageResponse = null] Nedarim gateway postMessage func
     * @param {{}} [donationParameters.gatewayToken = null] Payme/CardKnox token object
     * @return Promise<Object|null> Created donation
     */
    async createDonation(donationParameters) {
        const {
            campaignId,
            donations,
            paymentGateway,
            paymentMethod,
            props: {
                stripe = null,
                elements = null,
                getMessageResponse = null,
                formatMessage,
            },
            gatewayToken = null,
            recaptchaToken,
            matchedDonationId,
            donorPflSourceId,
        } = donationParameters;
        try {
            return await api.donation.donate({
                campaignId,
                flow: donationFlow(paymentGateway, paymentMethod),
                data: donations,
                voucher: getVoucherForDonation(paymentMethod),
                gateway: paymentGateway,
                props: {
                    stripe,
                    elements,
                    getMessageResponse,
                    formatMessage,
                },
                gatewayToken,
                recaptchaToken,
                matchedDonationId: matchedDonationId,
                donorPflSourceId,
            });
        } catch (error) {
            if (error.response) {
                throw new GatewayError(
                    `${error.response.data?.message} ${
                        error.response.data?.additionalMessage || ''
                    }`,
                );
            }
            throw new Error(error?.message || error);
        }
    }

    async confirmDonation(gateway, data) {
        try {
            const response = await this.axios.post(`/confirm/${gateway}`, data);
            return response;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : null
                : error;
            throw new Error(message);
        }
    }

    async receiptData(values) {
        try {
            const response = await this.axios.get(
                `/receipt/${values.gateway}/${values.transactionId}`,
            );
            return response;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : null
                : error;
            throw new Error(message);
        }
    }

    async sendReceipt(values) {
        try {
            const response = await this.axios.post(`/receipt`, values);
            return response;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : Object.values(errorData)[0]
                : error;
            throw new Error(message);
        }
    }

    async getPublicDonations(queryJson) {
        try {
            const queryString = Repository.prepareQueryString(queryJson);
            const response = axios.get(
                `public/donations${queryString ? `?${queryString}` : ''}`,
            );
            return response;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : null
                : error;
            throw new Error(message);
        }
    }

    async getTransactionStatus(donationTransactionId) {
        try {
            const response = await axios.get(
                `public/donation/${donationTransactionId}/status`,
            );
            return response.data;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : null
                : error;
            throw new Error(message);
        }
    }

    async getCampaignCompareDonations(queryJson) {
        try {
            const queryString = Repository.prepareQueryString(queryJson);
            const response = await axios.get(
                `donation/campaign-compare${
                    queryString ? `?${queryString}` : ''
                }`,
            );
            return response;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : null
                : error;
            throw new Error(message);
        }
    }

    async exportCampaignCompareDonations(queryJson) {
        try {
            const queryString = Repository.prepareQueryString(queryJson);
            const response = await axios.get(
                `donation/campaign-compare/export${
                    queryString ? `?${queryString}` : ''
                }`,
            );
            return response;
        } catch (error) {
            const errorData = error.response ? error.response.data : null;
            const message = errorData
                ? errorData.message
                    ? errorData.message
                    : null
                : error;
            throw new Error(message);
        }
    }
}

export default new DonationRepository({ baseUrl: '/donation' });
