import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageHeader from '../PageHeader/PageHeader';
import PageFooter from '../PageFooter/PageFooter';
import { makeStyles } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import messages from '../../locales.json';
import { languageDirection } from 'common/helpers/languages';
import caseInsensitiveEqual from 'common/helpers/caseInsensitiveEqual';

const useStyles = makeStyles(
    ({ palette, typography }) => ({
        '@global': {
            body: {
                lineHeight: 'inherit',
                margin: 0,
            },
        },

        root: {
            backgroundColor: palette.background.default,
            fontFamily: typography.fontFamily,
        },

        content: {
            flex: '1 0 auto',
            paddingTop: ({ hideHeader, smallHeader }) => {
                if (smallHeader) {
                    return 70;
                }
                return hideHeader ? 0 : 93;
            },
            '@media (max-width: 600px)': {
                paddingTop: ({ hideHeader, smallHeader }) => {
                    if (smallHeader) {
                        return 40;
                    }
                    return hideHeader ? 0 : 50;
                },
            },
        },
    }),
    { name: 'PageWrapper' },
);

function PageLayout(props) {
    const { children, slugs, locales, pageLanguage, hideHeader, hideFooter } =
        props;
    const languages = Object.entries(locales).map(
        ([slug, { language, pageName, displayPageName }]) => ({
            value: language,
            slug,
            pageName,
            displayPageName,
        }),
    );
    const showHeader = !hideHeader || languages.length > 1;
    const onlyShowLanguageSelector = hideHeader && languages.length > 1;

    const [lang, setLang] = useState(pageLanguage || 'he');
    const classes = useStyles({
        hideHeader,
        smallHeader: onlyShowLanguageSelector,
    });
    const [currentPage] = Object.entries(slugs).find(([, slug]) => {
        const match = document.location.pathname.match(/^\/([^/]+)\/?/i);

        if (match) {
            return caseInsensitiveEqual(slug, match[1]);
        }

        return false;
    });

    const initLanguage = language => {
        document.documentElement.setAttribute('lang', language);
        document.documentElement.setAttribute(
            'dir',
            languageDirection(language),
        );
        setLang(language);
    };

    useEffect(() => {
        initLanguage(pageLanguage || locales[currentPage].language);
    }, []);

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            <PageWrapper className={classes.root}>
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap"
                />
                {showHeader && (
                    <PageHeader
                        lang={lang}
                        slugs={slugs}
                        locales={locales}
                        currentPage={currentPage}
                        languages={languages}
                        onlyShowLanguageSelector={onlyShowLanguageSelector}
                    />
                )}
                <section className={classes.content}>{children}</section>
                {!hideFooter && <PageFooter lang={lang} />}
            </PageWrapper>
        </IntlProvider>
    );
}

PageLayout.propTypes = {
    slugs: PropTypes.object.isRequired,
    locales: PropTypes.object.isRequired,
    pageLanguage: PropTypes.string,
    children: PropTypes.node,
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
};

export default PageLayout;
