import { useEffect, useState } from 'react';
import useLoadScript from './loadScript';

export function useApplePay() {
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (window.ApplePaySession?.canMakePayments()) {
            setAllowed(true);
        }
    }, []);

    return allowed;
}

export function useGooglePay() {
    const [allowed, setAllowed] = useState(false);
    const status = useLoadScript('https://pay.google.com/gp/p/js/pay.js');

    useEffect(() => {
        if (status === 'ready' && window.google?.payments) {
            const googlePayClient =
                new window.google.payments.api.PaymentsClient({
                    environment: 'TEST',
                });
            const baseCardPaymentMethod = {
                type: 'CARD',
                parameters: {
                    allowedCardNetworks: [
                        'VISA',
                        'MASTERCARD',
                        'AMEX',
                        'DISCOVER',
                        'INTERAC',
                        'JCB',
                    ],
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                },
            };
            const googlePayBaseConfiguration = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [baseCardPaymentMethod],
            };
            googlePayClient
                .isReadyToPay(googlePayBaseConfiguration)
                .then(function (response) {
                    if (response.result) {
                        setAllowed(true);
                    }
                });
        }
    }, [status]);

    return allowed;
}
