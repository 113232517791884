import React, { useCallback, useEffect } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import useIsMobile from 'client/hooks/isMobile';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as yup from 'yup';
import Confetti from '../Confetti';
import {
    addDonationFlashMessage,
    startFlashMessagesQueue,
    stopFlashMessagesQueue,
} from './components/DonationFlasher/donationFlasherSlice';
import HeroBanner from './components/HeroBanner/HeroBanner';
import HeroSlider from './components/HeroSlider/HeroSlider';
import ItemDetailsBar from './components/ItemDetailsBar/ItemDetailsBar';
import PageCenterText from './components/PageCenterText/PageCenterText';
import PageLayout from './components/PageLayout/PageLayout';
import NewPerks from './components/Perks/NewDesign/Perks';
import OldPerks from './components/Perks/OldDesign/Perks';
import SharePanel from './components/SharePanel/SharePanel';
import {
    fetchBySlug,
    selectCampaignPageData,
    selectCampaignSlug,
    selectCurrencies,
    selectEnableDonations,
    selectGivings,
    selectHeroSlides,
    selectHideRdpMenu,
    selectLandingData,
    selectMatchRatio,
    selectSelectedCampaign,
    selectSelectedLayerItem,
    selectUseUpdatedPerkDesign,
    updateDonationsSum,
    updateGiving,
    updateSelectedLayerItemStatistics as updatePageLayerItemStatistics,
    updateCampaign,
    updateIsCampaignExpired,
    selectRdpHiddenTabs,
    selectPageName,
} from './features/campaign/campaignSlice';
import CheckoutPopup from './features/checkout/CheckoutPopup';
import { open } from './features/checkout/checkoutPopupSlice';
import DonationWidget from './features/checkout/DonationWidget';
import { slice as donorSlice } from './features/donorsList/donorsListSlice';
import { slice as teamDonorsListSlice } from './features/donorsList/teamDonorsListSlice';
import { slice as teamSlice } from './features/layersList/teamsListSlice';
import {
    selectIsDefaultTabSelected,
    selectLayersIsFetched,
    selectTabs,
    setIsDefaultTabSelected,
    setSelectedTab,
} from './features/recentDonationPanel/recentDonationPanelSlice';
import StatisticWidgets from './features/recentDonationPanel/StatisticWidgets';
import RecentDonationPanel from './features/recentDonationPanel/Tabs';
import useWebsocket from './hooks/ws';
import isDateStringPast from '../../../common/helpers/isDatePast';
import { updateDonorPflSourceId } from './features/campaign/campaignSlice';
import * as u2a from 'common/helpers/u2a';
import { campaignPageTheme as defaultCampaignPageTheme } from 'client/theme';
import updateThemePalette from 'client/helpers/campaign/updateThemePalette';
import classNames from 'classnames';
import { sendAnalyticsEvent } from '../../services/analytics';
import { parseCampaignPagePath } from '../../helpers/parseCampaignPagePath';

yup.addMethod(yup.mixed, 'requiredCardElement', function (message) {
    return this.test('testRequiredCardElement', message, function (value) {
        return value?.empty === false;
    });
});

yup.addMethod(yup.mixed, 'cardElementError', function () {
    return this.test({
        name: 'testCardElementError',
        test(value) {
            if (value?.error) {
                return this.createError({
                    message: value.error?.message,
                });
            }

            return true;
        },
    });
});

const useStyles = makeStyles(({ palette, typography, zIndex }) => ({
    previewMode: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '22px',
        backgroundColor: palette.secondary.main,
        color: palette.common.white,
        fontSize: typography.pxToRem(15),
        zIndex: zIndex.modal,
        borderBottom: `1px solid ${palette.grey[400]}`,
    },
    secondTagline: {
        marginTop: 45,
    },
}));

function CampaignPage() {
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const classes = useStyles();
    const campaign = useSelector(selectSelectedCampaign);
    const {
        showFirstTagline = false,
        showSecondTagline = false,
        firstTagline = null,
        secondTagline = null,
        defaultRDPTab = -1,
        enableFb,
        enableGa,
        enableGAd,
        enableGAdConversionTag,
        fbTrackingId,
        gaTrackingId,
        gAdTrackingId,
        gAdLabel,
        gAdConversionTagId,
        gAdConversionTagLabel,
        metaTitle,
        metaDescription,
        shareButtons,
    } = useSelector(selectLandingData);
    const campaignSlug = useSelector(selectCampaignSlug);
    const {
        bodyImage,
        mobileBodyImage,
        shortDescription,
        showShortDescription,
        name,
        isLayerItem,
        isMobileBodyImageEnabled,
        palette,
        hideHeader = false,
        hideFooter = false,
    } = useSelector(selectCampaignPageData);
    const {
        images: heroSlides,
        isVideoEnabled,
        video,
        mobileImages: mobileHeroSlides,
        interval,
        isMobileImagesEnabled,
    } = useSelector(selectHeroSlides);
    const multiplier = useSelector(selectMatchRatio);
    const currencies = useSelector(selectCurrencies);
    const givings = useSelector(selectGivings);
    const useUpdatedPerkDesign = useSelector(selectUseUpdatedPerkDesign);
    const rdpHiddenTabs = useSelector(selectRdpHiddenTabs);
    const rdpTabs =
        useSelector(selectTabs).filter(
            tab =>
                (!tab.layer || tab.counter) &&
                !tab.layer?.isHiddenFromRdp &&
                !rdpHiddenTabs?.includes(tab.tabContentId),
        ) || [];
    const enableDonations = useSelector(selectEnableDonations);
    // @todo: unify layer item selector, for any selected layer item type.
    const viewedLayerItem = useSelector(teamSlice.selectors.selectSelectedItem); // currently selected TeamCardPopup layer item
    const pageLayerItem = useSelector(selectSelectedLayerItem); // ItemDetailsBar layer item
    const hideRdpMenu = useSelector(selectHideRdpMenu);
    const pageName = useSelector(selectPageName);

    const amountRaised = Number(
        pageLayerItem?.statistics
            ? pageLayerItem.statistics.donationsAmount
            : pageLayerItem?.donationsAmount,
    );

    useEffect(() => {
        if (campaign?.id) {
            sendAnalyticsEvent({
                event: 'campaign:page_load',
                org_account_id: campaign.accountId,
                campaign_id: campaign.id,
                campaign_sub_page: pageName,
                page_language:
                    pageLayerItem?.language || document.documentElement.lang,
                is_layer_item_page: !!pageLayerItem,
            });
        }
    }, [campaign?.id, pageName, pageLayerItem]);

    const handleDonationApproved = useCallback(
        donor => {
            if (donor.campaign.id === campaign.id) {
                dispatch(
                    donorSlice.actions.updateTotal({
                        donationsCount: donor.campaign.donationsCount,
                    }),
                );
                dispatch(
                    teamSlice.actions.updateSelectedLayerItemStatistics(
                        donor.layerItems,
                    ),
                );
                dispatch(updatePageLayerItemStatistics(donor.layerItems));
                dispatch(donorSlice.actions.addDonor({ donor }));
                dispatch(addDonationFlashMessage(donor));
                if (
                    donor.layerItems?.find(
                        layerItem => layerItem.id === viewedLayerItem?.id,
                    )
                ) {
                    dispatch(teamDonorsListSlice.actions.addDonor({ donor }));
                }
                dispatch(updateDonationsSum(donor));

                if (donor.giving) {
                    dispatch(updateGiving({ donor }));
                }
            }
        },
        [viewedLayerItem, campaign && !!campaign.id],
    );
    const handleDonationDeleted = useCallback(
        donor => {
            if (donor.campaign.id === campaign.id) {
                dispatch(donorSlice.actions.decreaseTotal());
                dispatch(
                    teamSlice.actions.updateSelectedLayerItemStatistics(
                        donor.layerItems,
                    ),
                );
                dispatch(updatePageLayerItemStatistics(donor.layerItems));
                dispatch(donorSlice.actions.removeDonor({ donor }));
                dispatch(teamDonorsListSlice.actions.removeDonor({ donor }));
                dispatch(updateDonationsSum(donor));
            }
        },
        [campaign && !!campaign.id],
    );
    const handleDonationUpdate = useCallback(donor => {
        if (donor.campaign.id === campaign.id) {
            const allLayerItems = [
                ...(donor.layerItems || []),
                ...(donor.prevLayerItems || []),
            ];
            dispatch(
                teamSlice.actions.updateSelectedLayerItemStatistics(
                    allLayerItems,
                ),
            );
            dispatch(updatePageLayerItemStatistics(allLayerItems));

            dispatch(donorSlice.actions.updateDonor(donor));

            // try to update donation in RDP if it exists there
            // will be ignored if donation doesn't exist in RDP
            dispatch(teamDonorsListSlice.actions.updateDonor(donor));

            // if donation was moved to the current layer item page,
            // add it to the RDP
            if (
                donor.layerItems?.find(
                    layerItem => layerItem.id === viewedLayerItem?.id,
                )
            ) {
                dispatch(teamDonorsListSlice.actions.addDonor({ donor }));
            }

            // if donation was moved from the current layer item page,
            // remove it from the RDP
            if (
                donor.prevLayerItems?.find(
                    layerItem => layerItem.id === viewedLayerItem?.id,
                )
            ) {
                dispatch(teamDonorsListSlice.actions.removeDonor({ donor }));
            }

            dispatch(updateDonationsSum(donor));
            if (donor.giving) {
                dispatch(updateGiving({ donor }));
            }
        }
    });
    const handleCampaignUpdate = useCallback(updatedCampaign => {
        if (updatedCampaign.id === campaign.id) {
            dispatch(updateCampaign(updatedCampaign));
            dispatch(
                updateIsCampaignExpired(
                    isDateStringPast(updatedCampaign.endAt),
                ),
            );
        }
    });

    const handleDonationMatched = useCallback(
        ({ id, matchedDonationsCount }) => {
            dispatch(
                donorSlice.actions.updateMatchedDonationsCount({
                    id,
                    count: matchedDonationsCount,
                }),
            );
        },
    );

    const handleDonorPflCountUpdated = useCallback(updatedDonations => {
        for (let donation of updatedDonations) {
            dispatch(
                donorSlice.actions.updateDonationPflCountAndSlug({
                    id: donation.id,
                    donorPflCount: donation.donorPflCount,
                    donorPflSlug: donation.donorPflSlug,
                }),
            );
        }
    });

    useWebsocket({ 'donation:approved': handleDonationApproved });
    useWebsocket({ 'donation:delete': handleDonationDeleted });
    useWebsocket({ 'donation:update': handleDonationUpdate });
    useWebsocket({ 'donation:matched': handleDonationMatched });
    useWebsocket({ 'donorPfl:countUpdated': handleDonorPflCountUpdated });
    useWebsocket({ 'campaign:update': handleCampaignUpdate });
    useEffect(() => {
        const { slug, donorPflSlug, isDonate } = parseCampaignPagePath();
        dispatch(fetchBySlug(slug));
        isDonate && dispatch(open({ layerItem: viewedLayerItem }));
        if (donorPflSlug) {
            dispatch(updateDonorPflSourceId(u2a.b32decodeUInt(donorPflSlug)));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(startFlashMessagesQueue());
        return () => {
            dispatch(stopFlashMessagesQueue());
        };
    }, [dispatch]);

    const layersIsFetched = useSelector(selectLayersIsFetched);
    const isDefaultTabSelected = useSelector(selectIsDefaultTabSelected);

    //Set default tab after campaign is loaded
    useEffect(() => {
        if (
            campaign &&
            layersIsFetched &&
            !isDefaultTabSelected &&
            !isLayerItem
        ) {
            let newSelectedTab = rdpTabs.find(tab => tab.id === defaultRDPTab);
            const fallbackTab =
                rdpTabs.find(tab => tab.tabContentId === 'donors') ||
                rdpTabs[0];

            if (
                !newSelectedTab ||
                newSelectedTab?.layer?.layerItemsCount === 0
            ) {
                newSelectedTab = fallbackTab;
            } else {
                dispatch(setIsDefaultTabSelected(true));
            }

            dispatch(setSelectedTab(newSelectedTab));
        }
    }, [campaign, layersIsFetched]);

    useEffect(() => {
        if (campaignSlug) {
            triggerGtmEvent(GTM_EVENTS.TRACK_INIT, {
                fbIdArray: enableFb ? fbTrackingId : 'false',
                gaIdArray: enableGa ? gaTrackingId : 'false',
                gAdId: enableGAd ? gAdTrackingId : 'false',
                gAdLabel: enableGAd ? gAdLabel : '',
                gAdConversionTagId: enableGAdConversionTag
                    ? gAdConversionTagId
                    : 'false',
                gAdConversionTagLabel: enableGAdConversionTag
                    ? gAdConversionTagLabel
                    : 'false',
                campaignId: campaign.id,
            });
        }
    }, [campaignSlug]);

    if (!campaign) {
        return null;
    }

    const getBodyImage = () => {
        if (isMobile) {
            return isMobileBodyImageEnabled ? mobileBodyImage : '';
        }
        return bodyImage;
    };

    const customCampaignTheme = updateThemePalette(
        defaultCampaignPageTheme,
        palette,
    );

    return (
        <ThemeProvider theme={defaultCampaignPageTheme}>
            <PageLayout
                slugs={campaign.slugs}
                locales={campaign.locales}
                pageLanguage={
                    pageLayerItem?.language || document.documentElement.lang
                }
                hideHeader={hideHeader}
                hideFooter={hideFooter}
            >
                {!campaign.published && (
                    <div className={classes.previewMode}>
                        PREVIEW MODE{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`/dashboard/campaign/${campaign.id}`}
                        >
                            open in Dashboard
                        </a>
                    </div>
                )}
                <ThemeProvider theme={customCampaignTheme}>
                    <BrowserRouter>
                        <Switch>
                            <Route path="**/donate">
                                <CheckoutPopup />
                            </Route>
                            <Route>
                                <>
                                    <Confetti />
                                    {(heroSlides.length > 0 ||
                                        mobileHeroSlides?.length > 0 ||
                                        isVideoEnabled) && (
                                        <HeroSlider
                                            slides={heroSlides}
                                            video={video}
                                            isVideoEnabled={isVideoEnabled}
                                            mobileSlides={mobileHeroSlides}
                                            interval={interval}
                                            isMobileImagesEnabled={
                                                isMobileImagesEnabled
                                            }
                                        />
                                    )}
                                    {isLayerItem && (
                                        <ItemDetailsBar
                                            name={name}
                                            avatarSrc={pageLayerItem.logo}
                                            currency={pageLayerItem.currency}
                                            goalAmount={Math.round(
                                                Number(pageLayerItem.goal),
                                            )}
                                            bonusGoalAmount={Math.round(
                                                Number(
                                                    pageLayerItem?.bonusGoal,
                                                ),
                                            )}
                                            hasEnteredBonusGoal={
                                                pageLayerItem?.statistics
                                                    ?.hasEnteredBonusGoal
                                            }
                                            amountRaised={amountRaised}
                                            donorsCount={Number(
                                                pageLayerItem.statistics
                                                    ? pageLayerItem.statistics
                                                          .donorsCount
                                                    : pageLayerItem.donorsCount,
                                            )}
                                        />
                                    )}
                                    {showFirstTagline && (
                                        <PageCenterText
                                            className="tagline-1"
                                            alignment="auto"
                                            html
                                        >
                                            {firstTagline}
                                        </PageCenterText>
                                    )}
                                    <StatisticWidgets />
                                    <DonationWidget
                                        disabled={!campaign.published}
                                        multiplier={multiplier}
                                        currencies={currencies}
                                    />
                                    {showShortDescription && (
                                        <PageCenterText
                                            className="short-description"
                                            alignment="auto"
                                            html
                                        >
                                            {shortDescription}
                                        </PageCenterText>
                                    )}
                                    {givings.length !== 0 &&
                                    useUpdatedPerkDesign ? (
                                        <NewPerks
                                            givings={givings}
                                            isPublished={campaign.published}
                                            enableDonations={enableDonations}
                                        />
                                    ) : (
                                        <OldPerks
                                            givings={givings}
                                            isPublished={campaign.published}
                                            enableDonations={enableDonations}
                                        />
                                    )}
                                    <HeroBanner
                                        withMarginTop
                                        src={getBodyImage()}
                                    />
                                    {showSecondTagline && (
                                        <PageCenterText
                                            className={classNames(
                                                classes.secondTagline,
                                                'tagline-2',
                                            )}
                                            alignment="auto"
                                            html
                                        >
                                            {secondTagline}
                                        </PageCenterText>
                                    )}

                                    {!hideRdpMenu && (
                                        <RecentDonationPanel
                                            multiplier={multiplier}
                                            campaignId={campaign.id}
                                            tabs={rdpTabs}
                                        />
                                    )}
                                </>
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </ThemeProvider>

                {campaignSlug && shareButtons && (
                    <SharePanel
                        campUrl={window.location.href.split(/#|\?/)[0]}
                        title={metaTitle}
                        text={metaDescription}
                    />
                )}
            </PageLayout>
        </ThemeProvider>
    );
}

export default CampaignPage;
