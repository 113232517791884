import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
    PaymentRequestButtonElement,
    useStripe,
} from '@stripe/react-stripe-js';
import Loader from 'client/components/Loader';
import focusElement from 'client/helpers/focusElement';
import { selectDonationTotal } from './checkoutSlice';
import { PAYMENT_MODES } from 'common/constants';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});
const useOptions = paymentRequest => {
    const options = useMemo(
        () => ({
            paymentRequest,
            style: {
                paymentRequestButton: {
                    theme: 'dark',
                    height: '48px',
                    type: 'donate',
                },
            },
        }),
        [paymentRequest],
    );

    return options;
};

const usePaymentRequest = ({
    currency = 'usd',
    total = {
        label: 'Causematch campaign',
        amount: 100,
    },
    onPaymentMethod,
    isDisabled = false,
}) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [canMakePayment, setCanMakePayment] = useState(null);

    const options = {
        country: 'US',
        currency,
        total,
        requestPayerName: true,
        requestPayerEmail: true,
    };

    useEffect(() => {
        if (isDisabled) {
            return;
        }

        if (stripe && paymentRequest === null) {
            const pr = stripe.paymentRequest(options);
            setPaymentRequest(pr);
        }
    }, [isDisabled, stripe, options, paymentRequest]);

    useEffect(() => {
        if (isDisabled) {
            return;
        }

        let subscribed = true;
        if (paymentRequest) {
            paymentRequest.canMakePayment().then(res => {
                if (res && subscribed) {
                    setCanMakePayment(res);
                }
            });
        }

        return () => {
            subscribed = false;
        };
    }, [isDisabled, paymentRequest]);

    useEffect(() => {
        if (isDisabled) {
            return;
        }

        if (paymentRequest && onPaymentMethod) {
            paymentRequest.on('paymentmethod', onPaymentMethod);
        }
        return () => {
            if (paymentRequest && onPaymentMethod) {
                paymentRequest.off('paymentmethod', onPaymentMethod);
            }
        };
    }, [isDisabled, paymentRequest, onPaymentMethod]);

    return {
        paymentRequest: canMakePayment ? paymentRequest : null,
        canMakePayment,
    };
};

const PaymentRequestForm = ({
    onDonateSuccess,
    makeDonation,
    campaignLabel = 'Causematch Campaign',
    paymentMode,
}) => {
    const classes = useStyles();
    const { getValues, handleSubmit, formState, trigger } = useFormContext();
    const [isLoading, setIsLoading] = useState(true);
    const { totalAmount, monthlyAmount, tipAmount } =
        useSelector(selectDonationTotal);

    const amount =
        paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING
            ? Math.round((monthlyAmount + tipAmount) * 100)
            : Math.round(totalAmount * 100);
    const paymentLabel =
        paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING
            ? `${campaignLabel} - Monthly Donation`
            : campaignLabel;
    const currency = (getValues('currency') || 'USD').toLowerCase();

    const { paymentRequest } = usePaymentRequest({
        currency,
        total: {
            label: paymentLabel,
            amount,
        },
        onPaymentMethod: async ({ complete, paymentMethod }) => {
            const { layerItems, ...donationData } = getValues();
            const donations = await makeDonation(
                donationData,
                layerItems,
                paymentMethod,
            );

            if (typeof onDonateSuccess === 'function' && donations) {
                onDonateSuccess(donations);
            }

            complete('success');
        },
    });
    const options = useOptions(paymentRequest);

    const onClick = useCallback(
        async event => {
            const isValid = await trigger();
            if (!isValid) {
                event.preventDefault();
                await handleSubmit(
                    () => {},
                    errors => {
                        if (errors?.amount) {
                            setTimeout(
                                () => focusElement('[name="amount"]'),
                                25,
                            );
                        }
                    },
                )();
            }
        },
        [formState.isValid],
    );

    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.update({
                total: {
                    label: paymentLabel,
                    amount,
                },
            });
        }
    }, [paymentRequest, amount]);

    if (!paymentRequest) {
        return null;
    }

    return (
        <div className={classes.root}>
            {isLoading && <Loader />}
            <PaymentRequestButtonElement
                className="PaymentRequestButton"
                options={options}
                onClick={onClick}
                onReady={() => {
                    setIsLoading(false);
                }}
            />
        </div>
    );
};

PaymentRequestForm.propTypes = {
    onDonateSuccess: PropTypes.func.isRequired,
    makeDonation: PropTypes.func.isRequired,
    campaignLabel: PropTypes.string,
    paymentMode: PropTypes.string,
};

export { usePaymentRequest };

export default PaymentRequestForm;
