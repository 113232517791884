import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { deepmerge } from '@material-ui/utils';
import { CAMPAIGN_THEME_PALETTE } from 'common/constants/campaign';

const checkboxColor = {
    color: grey[600],
    '&$checked': {
        color: '#4465FF',
    },
};

const orangeGradient =
    '#FFCA37 linear-gradient(271deg, #FFCA37 0%, #FFB437 100%) 0% 0% no-repeat padding-box';
const switchOrangeGradient =
    '#FFCA37 linear-gradient(77deg, #FFDD37 0%, #FF7C37 100%) 0% 0% no-repeat padding-box';

const defaultMuiTheme = createMuiTheme();

export const defaultThemeOptions = {
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main
            main: '#4465FF', // blue
            // dark: will be calculated from palette.primary.main
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#FFB31D', // orange
        },
        // `augmentColor` creates all relevant shades and contrast text based on the `main` color
        accent: defaultMuiTheme.palette.augmentColor({
            main: '#293040', // dark blue (gunmetal)
            secondary: '#7586AD', // TODO: name and place this better
        }),
        error: {
            main: '#ff3c00', // red
            contrastText: '#fff',
        },
        warning: {
            main: '#FFD436', // yellow
            contrastText: '#4a4a4a',
        },
        success: {
            main: '#5ea500', // green
            contrastText: '#fff',
        },
        info: {
            main: '#E6E6E6', // light gray
        },
        background: {
            primaryLight: '#FAFCFF',
            accent: '#E7EBFF', // TODO: name and place this better
        },
        brands: {
            whatsapp: '#25D366',
            facebook: '#4267B2',
            twitter: '#1DA1F2',
            linkedin: '#0077b5',
            bit: {
                primary: '#2E678E',
                secondary: '#37A4AF',
            },
            matbia: '#44479D',
            stripe: {
                lightPurple: '#7a73ff',
                darkPurple: '#635bff',
                contrastText: '#fff',
            },
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: {
            light: 0.3,
            dark: 0.2,
        },
        orangeGradient,
    },
    overrides: {
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: defaultMuiTheme.spacing(1),
            },
        },
        MuiFormControl: {
            root: {
                zIndex: 'auto',
            },
        },
        MuiButton: {
            root: {
                borderRadius: '999em',
                paddingTop: '2px',
                paddingBottom: '2px',
                lineHeight: 1.7,
                background: ({ color, variant }) => {
                    if (color === 'secondary') {
                        return orangeGradient;
                    }

                    if (variant === 'contained') {
                        return defaultThemeOptions.palette[color]?.main;
                    }
                },
                textTransform: 'none',
            },
            containedSecondary: {
                color: '#fff',
            },
        },
        MuiCheckbox: {
            root: {
                ...checkboxColor,
            },
            colorSecondary: {
                ...checkboxColor,
            },
            colorPrimary: {
                ...checkboxColor,
            },
        },
        MuiSwitch: {
            root: {
                width: 28,
                height: 16,
                padding: 0,
                margin: defaultMuiTheme.spacing(1),
            },
            switchBase: {
                padding: 3,
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: defaultMuiTheme.palette.common.white,
                    '& + $track': {
                        background: switchOrangeGradient,
                        opacity: 1,
                        border: 'none',
                    },
                    '& $thumb': {
                        background: defaultMuiTheme.palette.common.white,
                    },
                },
            },
            colorSecondary: {
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: defaultMuiTheme.palette.common.white,
                    '& + $track': {
                        background: switchOrangeGradient,
                        opacity: 1,
                        border: 'none',
                    },
                    '& $thumb': {
                        background: defaultMuiTheme.palette.common.white,
                    },
                },
            },
            thumb: {
                width: 10,
                height: 10,
                border: 'none',
                boxShadow: 'none',
                background: orangeGradient,
            },
            track: {
                borderRadius: 16 / 2,
                border: `1px solid ${grey[300]}`,
                backgroundColor: grey[300],
                opacity: 1,
                transition: defaultMuiTheme.transitions.create([
                    'background-color',
                    'border',
                ]),
            },
            checked: {},
        },
        MuiTabs: {
            root: {
                minHeight: 29,
                padding: `${defaultMuiTheme.spacing(
                    2,
                )}px ${defaultMuiTheme.spacing(4)}px`,
                backgroundColor: '#EAEAEA',
            },
            indicator: {
                '&:before': {
                    content: '""',
                    border: `1px solid #4465FF`,
                    position: 'absolute',
                    height: 2,
                    top: -34,
                    width: '100%',
                },
            },
        },
        MuiTab: {
            root: {
                paddingLeft: 6,
                paddingRight: 6,
                minHeight: 29,
                textTransform: 'none',
            },
        },
        MuiInputAdornment: {
            root: {
                '& p': {
                    fontSize: '0.8rem',
                    color: grey[600],
                },
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#E7EBFF',

                '& a, & th': {
                    color: grey[600],
                    fontWeight: 400,
                },

                '& .MuiTableCell-root': {
                    borderBottom: `1px solid ${grey[400]}`,
                },
            },
        },
        MuiTableCell: {
            root: {
                padding: `${defaultMuiTheme.spacing(
                    1,
                )}px ${defaultMuiTheme.spacing(2)}px`,
                borderBottomColor: grey[200],
                '& .MuiSwitch-root': {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
            head: {
                padding: `${defaultMuiTheme.spacing(
                    1,
                )}px ${defaultMuiTheme.spacing(2)}px`,
            },
        },
        MuiPaper: {
            root: {
                padding: 0,
                backgroundColor: '#F7F7F7',
            },
        },
        MuiTable: {
            root: {
                backgroundColor: '#fff',
            },
        },
    },
    typography: {
        body1: {
            color: '#808080',
        },
        fontFamily: 'Heebo, sans-serif',
    },
};

export const campaignPageThemeOptions = {
    palette: CAMPAIGN_THEME_PALETTE,
    typography: {
        body1: {
            color: '#4a4a4a',
        },
        fontFamily: 'Heebo, sans-serif',
    },
    overrides: {
        MuiPaper: {
            root: {
                padding: 0,
                backgroundColor: '#fff',
            },
        },
    },
};

export const dashboardTheme = createMuiTheme(defaultThemeOptions);
export const campaignPageTheme = deepmerge(
    dashboardTheme,
    createMuiTheme(campaignPageThemeOptions),
);
