import React, { useEffect, useCallback } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PageSection from 'client/components/CampaignPage/components/PageSection/PageSection';
import DonationTabs from 'client/components/CampaignPage/components/DonationTabs/DonationTabs';
import DonationTab from 'client/components/CampaignPage/components/DonationTab/DonationTab';
import iconsMap from 'client/components/CampaignPage/components/DonationTab/iconsMap';
import { slice as teamSlice } from '../layersList/teamsListSlice';
import {
    fetchLayers,
    fetchChildLayers,
    setSelectedTab,
    selectSelectedTab,
    selectLayersIsFetched,
    selectChildLayersParentId,
    setTabCounter,
} from './recentDonationPanelSlice';
import {
    selectRdpOrder,
    selectRdpLabel,
} from 'client/components/CampaignPage/features/campaign/campaignSlice';
import TabContent from './TabContent';
import useDonorsList from '../donorsList/useDonorsList';
import { slice as donorSlice } from '../donorsList/donorsListSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import useIsMobile from 'client/hooks/isMobile';

const useStyles = makeStyles(
    {
        root: {
            marginTop: 60,
            marginBottom: 50,

            '@media (max-width: 600px)': {
                marginTop: 20,
                padding: '0 30px',
            },
        },
        tabs: {
            '@media (max-width: 600px)': {
                marginLeft: -30,
                marginRight: -30,
            },
        },
    },
    { name: 'RecentDonationPanel' },
);

const reorderTabs = (rdpOrder, tabs) => {
    const layerTabs = tabs.filter(tab => !isEmpty(tab?.layer));
    const reorderedTabs = [...layerTabs];

    const donorsTab = tabs.find(tab => tab.tabContentId === 'donors');
    const aboutTab = tabs.find(tab => tab.tabContentId === 'about');

    if (donorsTab) {
        reorderedTabs.splice(rdpOrder.donorsTab ?? 0, 0, donorsTab);
    }
    if (aboutTab) {
        reorderedTabs.splice(rdpOrder.aboutTab ?? tabs.length - 1, 0, aboutTab);
    }

    return reorderedTabs;
};

function RecentDonationPanel(props) {
    const { multiplier, campaignId, tabs } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const intl = useIntl();
    const lang = intl.locale;
    const rdpOrder = useSelector(selectRdpOrder);
    const rdpLabel = useSelector(selectRdpLabel);
    const sortedTabs = reorderTabs(rdpOrder, tabs);
    const selectedTab = useSelector(selectSelectedTab);
    const selectedLayerItem = useSelector(
        teamSlice.selectors.selectSelectedItem,
    );
    const layersIsFetched = useSelector(selectLayersIsFetched);
    const childLayersParentId = useSelector(selectChildLayersParentId);
    const handleChangeTab = useCallback(
        tab => {
            if (selectedTab.id !== tab.id) {
                dispatch(teamSlice.actions.resetItems());
                dispatch(teamSlice.actions.resetItemsAfterLoad());
                dispatch(teamSlice.actions.resetQuery());
                dispatch(teamSlice.actions.selectItem(null));
                dispatch(teamSlice.actions.selectChildLayer(null));
                dispatch(setSelectedTab(tab), []);
                let tabName = `${tab.tabContentId}-tab`;
                if (tab.tabContentId == 'about the\ncampaign') {
                    tabName = 'about-tab';
                }
                triggerGtmEvent(GTM_EVENTS.CLICK_TAB, {
                    eventCategory: tabName,
                });
            }
        },
        [dispatch, selectedTab],
    );

    function isActive(tab) {
        return tab.id === selectedTab.id;
    }

    useEffect(() => {
        if (!layersIsFetched) {
            dispatch(fetchLayers(campaignId));
        }
    }, []);

    useEffect(() => {
        if (
            selectedLayerItem?.layer?.id &&
            childLayersParentId !== selectedLayerItem.layer.id
        ) {
            dispatch(
                fetchChildLayers({
                    layerItemId: selectedLayerItem.id,
                }),
            );
        }
    }, [selectedTab, selectedLayerItem]);

    //Set donors tab counter
    const donorListProps = useDonorsList(donorSlice);
    const { donorsCount } = donorListProps;
    useEffect(() => {
        dispatch(
            setTabCounter({
                tabId: -1,
                counter: donorsCount,
            }),
        );
    }, [donorsCount]);

    const getTabLabel = tab => {
        const label = get(rdpLabel, `${tab.tabContentId}.${lang}`, null);
        if (label && tab.tabContentId === 'donors') {
            return (tab.counter || 0) + '\n' + label;
        }
        return (
            label || (
                <FormattedMessage
                    id={tab.translationId ? tab.translationId : tab.label}
                    values={{
                        counter: tab.counter || 0,
                    }}
                />
            )
        );
    };

    if (sortedTabs.length < 1) {
        return null;
    }

    return (
        <PageSection className={classNames(classes.root, 'rdp')}>
            <DonationTabs className={classNames(classes.tabs, 'rdp-tabs')}>
                {sortedTabs.map(tab => {
                    let layerLabel;
                    if (tab.layer) {
                        const localeData =
                            tab.layer.locales[lang] || tab.layer.locales['en'];
                        layerLabel = localeData.name || tab.name;
                    }
                    const icon =
                        isMobile && tab.tabContentId === 'donors'
                            ? null
                            : iconsMap[tab.icon];

                    return (
                        <DonationTab
                            key={tab.id}
                            className={`tab-${tab.id} rdp-tab`}
                            iconStart={icon || null}
                            active={isActive(tab)}
                            onClick={() => handleChangeTab(tab)}
                        >
                            <span style={{ whiteSpace: 'pre-line' }}>
                                {tab.layer
                                    ? (tab.counter || 0) + '\n' + layerLabel
                                    : getTabLabel(tab)}
                            </span>
                        </DonationTab>
                    );
                })}
            </DonationTabs>
            <TabContent
                multiplier={multiplier}
                tab={selectedTab}
                donorListProps={donorListProps}
            />
        </PageSection>
    );
}

RecentDonationPanel.propTypes = {
    campaignId: PropTypes.number.isRequired,
    multiplier: PropTypes.number,
    tabs: PropTypes.array.isRequired,
};

export default RecentDonationPanel;
